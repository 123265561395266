.App {
    text-align: center;
  }
  
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
    height: 80px;
  }
  
  .App-header {
    background-color: blue;
    height: 10%;
    padding: 20px;
    color: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    
  }
  

.input {
    
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
    /*border: 2px solid red*/
    
  }

  .input_mobile {
    
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
    
  }

  .CircleSwitch_main { 
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-bottom: 80;
     }

  .CircleSwitch { 
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
    }