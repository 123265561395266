.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: blue;
  height: 10px;
  color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  /*border: 2px solid red*/

  
}

.App-header-text {
    font-size: 30px;
    font-weight: 600;
}

.Device-container {
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
 
}


.App-icon {
  color : green;
}

.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}
