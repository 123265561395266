.App {
    text-align: center;
    
  }
  
.h1 {
  color: blue;
}

.h2 {
  color : blue
}

  .App-logo {
    animation: App-logo-spin infinite 20s linear;
    height: 80px;
  }
  
  .App-header {
    background-color: blue;
    height: 10%;
    padding: 20px;
    color: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    
  }
  

.input {
    
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
    /*border: 2px solid red*/
    
  }

  .input_mobile {
    
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
    
  }

  .CircleSwitch_main { 
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-bottom: 80;
     }

  .CircleSwitch { 
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
    }

  .Icon_main {
    display : flex ;
    margin-top : 40px;
    /*margin-left: 90px;*/
    align-items : center;
    flex-direction: column;
    border: 3px solid red;
    width: 120px;
  }

  .Icon_group {
    display : flex ;
    flex-direction: row;
    justify-content: space-around;
  }

  .react-icons {
    vertical-align: middle;
    /*border: 3px solid green;*/
    
  }

  .table {
    display: flex;
    vertical-align: middle;
    /*border: 3px solid red;*/
    justify-content: center;
    
  }

  .two_buttons {
    margin-top: 20px;
    /*border: 3px solid red;*/
    display : flex;
    flex-direction : row;
    justify-content: space-evenly;
  }

  .App-header-text {
    font-size: 30px;
    font-weight: 600;
}

.App-header-text-mobile {
  font-size: 15px;
  font-weight: 400;
 
}

.App-header-mobile {
  background-color: blue;
  height: 25px;
  padding: 20px;
  color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  
}